<template>
  <div>
    <!-- desktop -->
    <div class="d-none d-md-block">
      <v-container
        grid-list-sm
        class="px-16"
      >
        <v-row>
          <v-col cols="12">
            <p
              class="mb-10 OpenSansSemibold mediumBlueMine punt61Mine"
              style="text-align: center;"
            >
              {{ $t('landing.secSix.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row class="px-16">
          <v-col cols="4">
            <template>
              <v-card
                class="mx-auto"
                max-width="344"
                elevation="6"
                tile
                height="430"
                style="background-color: #1a566653;"
              >
                <div
                  style="width:100%; height:auto; background-color: white;"
                  class="pa-6"
                >
                  <v-img
                    :lazy-src="`${urlS3}wamasolplus-03.png`"
                    :src="`${urlS3}wamasolplus-03.png`"
                  ></v-img>
                </div>
                <v-card-text class="pt-6 px-6">
                  <b>{{ $t('landing.secSix.plus') }}</b>
                  {{ $t('landing.secSix.plus1') }}
                  <ul class="pt-1">
                    <li>{{ $t('landing.secSix.plus2') }}</li>
                    <li>{{ $t('landing.secSix.plus3') }}</li>
                  </ul>
                </v-card-text>
              </v-card>
            </template>
          </v-col>

          <v-col cols="4">
            <template>
              <v-card
                class="mx-auto"
                max-width="344"
                elevation="6"
                tile
                height="430"
                style="background-color: #f0831953;"
              >
                <div
                  style="width:100%; height:auto; background-color: white;"
                  class="pa-6"
                >
                  <v-img
                    :lazy-src="`${urlS3}wamasoltours-03.png`"
                    :src="`${urlS3}wamasoltours-03.png`"
                    style="cursor: pointer"
                    @click="redirect('https://wamasoltours.com')"
                  ></v-img>
                </div>
                <v-card-text class="pt-6 px-6">
                  <b>{{ $t('landing.secSix.tour') }}</b>
                  {{ $t('landing.secSix.tour1') }}<br />
                  {{ $t('landing.secSix.tour2') }}
                </v-card-text>
              </v-card>
            </template>
          </v-col>

          <v-col cols="4">
            <template>
              <v-card
                class="mx-auto"
                max-width="344"
                elevation="6"
                tile
                height="430"
                style="background-color: #0198c753;"
              >
                <div
                  style="width:100%; height:auto; background-color: white;"
                  class="pa-6"
                >
                  <v-img
                    :lazy-src="`${urlS3}wamasoltech-03.png`"
                    :src="`${urlS3}wamasoltech-03.png`"
                  ></v-img>
                </div>
                <v-card-text class="pt-6 px-6">
                  <b>{{ $t('landing.secSix.tech1') }}</b>
                  {{ $t('landing.secSix.tech2') }}
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- movil -->
    <div class="d-md-none">
      <v-container grid-list-xm>
        <v-row>
          <v-col cols="12">
            <p
              class="OpenSansSemibold mediumBlueMine punt61MineMov"
              style="text-align: center;"
            >
              {{ $t('landing.secSix.title') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-slide-group
        v-model="model"
        show-arrows
      >
        <v-slide-item>
          <v-card
            class="mr-4"
            height="auto"
            width="250"
            elevation="6"
            tile
            style="background-color: #1a566653;"
          >
            <div
              style="width:250px; height:100px; background-color: white;"
              class="pa-3"
            >
              <v-img
                :lazy-src="`${urlS3}wamasolplus-03.png`"
                :src="`${urlS3}wamasolplus-03.png`"
              ></v-img>
            </div>
            <v-card-text class="pa-4">
              <p class="standarMine OpenSansLight">
                <b class="standarMine OpenSansLight">{{ $t('landing.secSix.plus') }}</b>
                {{ $t('landing.secSix.plus1') }}
              </p>
              <ul class="pt-1">
                <li class="standarMine OpenSansLight">
                  {{ $t('landing.secSix.plus2') }}
                </li>
                <li class="standarMine OpenSansLight">
                  {{ $t('landing.secSix.plus3') }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-slide-item>
        <v-slide-item>
          <v-card
            class="mr-4"
            height="auto"
            width="250"
            elevation="6"
            tile
            style="background-color: #f0831953;"
          >
            <div
              style="width:250px; height:100px; background-color: white;"
              class="pa-3"
            >
              <v-img
                :lazy-src="`${urlS3}wamasoltours-03.png`"
                :src="`${urlS3}wamasoltours-03.png`"
                style="cursor: pointer"
                @click="redirect('https://wamasoltours.com')"
              ></v-img>
            </div>
            <v-card-text class="pa-4">
              <p class="mb-0 standarMine OpenSansLight">
                <b class="standarMine">{{ $t('landing.secSix.tour') }}</b>
                {{ $t('landing.secSix.tour1') }}<br />
                {{ $t('landing.secSix.tour2') }}
              </p>
            </v-card-text>
          </v-card>
        </v-slide-item>
        <v-slide-item>
          <v-card
            class="mr-4"
            height="auto"
            width="250"
            elevation="6"
            tile
            style="background-color: #0198c753;"
          >
            <div
              style="width:250px; height:100px; background-color: white;"
              class="pa-3"
            >
              <v-img
                :lazy-src="`${urlS3}wamasoltech-03.png`"
                :src="`${urlS3}wamasoltech-03.png`"
              ></v-img>
            </div>
            <v-card-text class="pa-4 standarMine OpenSansLight">
              <p class="standarMine OpenSansLight">
                <b class="standarMine OpenSansLight">{{ $t('landing.secSix.tech1') }}</b>
                {{ $t('landing.secSix.tech2') }}
              </p>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiChevronDown } from '@mdi/js'

export default {
  data: () => ({
    urlS3: process.env.VUE_APP_URL_S3,
    model: null,
  }),
  setup() {
    return {
      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
      },
    }
  },
  methods: {
    redirect(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.whiteMine {
  color: white;
}

.punt52Mine {
  font-size: 36px;
}

.punt52MineMov {
  font-size: 26px;
}

.punt61Mine {
  font-size: 40px;
}

.punt61MineMov {
  font-size: 30px;
}

.punt35Mine {
  font-size: 20px;
}

.punt35MineMov {
  font-size: 15px;
}

.punt32Mine {
  font-size: 19px;
}

.punt32MineMov {
  font-size: 14px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
